
.body {
    height: 100vh;
    font-family: 'Poppins', sans-serif, arial;
    background-color: #ffffff;
    margin: 0 -15px;
}
.upperContent {
    margin: 0 auto;
    background-color: white;
    text-align: center;
    color: #111111;
    height: 60px;
}

/* Landscape phones and down */
@media (max-width: 540px) {
    .upperContent {
        max-width: 100%;
    }
}
@media (min-width: 541px) {
    .upperContent {
        max-width: 48%;
    }
}

.upperImage {
    display: block;
    margin: auto;
    height: 40px;
    width: 80px;
}

.upperLinkLeft {
    padding: 20px 0 0 20px;
    float: left;
}

.upperLinkRight {
    color: #111111;
    font-size: 16.5px;
    text-decoration: none;
    padding: 28px 20px 10px 0;
    float: right;
    font-weight: 300;
}

.middleContent {
    background-color: white;
    font-size: 15px;
    text-align: center;
    color: #111111;
    display: table;
    height: calc(100% - 143px);
    /*height: 100%;*/
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
}

.middleChild {
    display: table-cell;
    vertical-align: middle;
}

.middleImage {
    margin: auto;
}
.middleImage img{
    max-width: 304px;
}

.footer {
    background-color: #fcee37;
    /*margin: 0 auto;*/
    margin: 0;
    text-align: right;
    font-size: 13px;
    width: 100%;
    height: 80px;
    left: 0;
}

.footerText {
    padding: 30px;
    color: #111111;
}

.subject {
    text-align: center;
    margin: auto;
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.3;
    max-width: 410px;
}

.description {
    font-size: 15px;
    margin: auto;
    max-width: 410px;
}
/* ////////////// LOADER //////////// */

.ldsRing {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(61,179,195) transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
