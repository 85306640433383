
.body {
    height: 100vh;
    font-family: 'Poppins', sans-serif, arial;
    background-color: #ffffff;
    margin: 0 -15px;
}

.upperContent {
    max-width: 48%;
    margin: 0 auto;
    background-color: white;
    text-align: center;
    color: #111111;
}

.upperImage {
    display: block;
    margin: auto;
    height: 40px;
    width: 80px;
}

.upperLinkLeft {
    padding: 20px 0px 0px 0px;
    float: left;
}

.upperLinkRight {
    color: #111111;
    font-size: 16.5px;
    text-decoration: none;
    padding: 28px 0px 10px 0px;
    float: right;
    font-weight: 300;
}

.middleContent {
    max-width: 48%;
    margin: 0 auto;
    background-color: white;
    font-size: 15px;
    text-align: center;
    color: #111111;
    display: table;
    height: calc(100% - 143px);
}

.middleChild {
    display: table-cell;
    vertical-align: middle;
}

.middleImage {
    margin: 30px auto 10px auto;
}

.footer {
    background-color: #fcee37;
    /*margin: 0 auto;*/
    margin: 0;
    text-align: right;
    bottom: 0;
    font-size: 13px;
    position: absolute;
    width: 100%;
    left: 0;
}

.footerText {
    padding: 30px;
    color: #111111;
}

.subject {
    text-align: center;
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.3;

}

.description {
    font-size: 15px;
    margin: 0 auto 0px auto;
    max-width: 90%;
}
