.card {
    width: 500px;
    padding: 25px 20px !important;
}

@media(max-width: 540px){
    .card{
        width: 100%;
    }
}

.title {
    font-size: 20px;
    color: black;
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
}

.field{
    margin-bottom:20px;
}

.button {
    margin-top: 5px;
    background-color: #8bc563 !important;
    border: 0;
    width: 70%;
    border-radius: 30px;
    font-size: 14px !important;
    padding: 12px 15px 10px 15px !important;
}

.input {
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #c8c8c8;
}

.label {
    margin-bottom: 5px;
}

.error {
    color: red;
    margin-bottom: 20px;
    text-align: left;
}