
.body {
    /*height: 100vh;*/
    height: 100%;
    font-family: 'Poppins', sans-serif, arial;
    background-color: #ffffff;
    margin: 0 -15px;
}

.upper-content {
    max-width: 48%;
    margin: 0 auto;
    background-color: white;
    text-align: center;
    color: #111111;
    min-height: 62px;
}

.upper-image {
    display: block;
    margin: auto;
    height: 40px;
    width: 80px;
}

.upper-link-left {
    padding: 20px 0 0 0;
    float: left;
}

.upper-link-right {
    color: #111111;
    font-size: 16.5px;
    text-decoration: none;
    padding: 28px 0 10px 0;
    float: right;
    font-weight: 300;
}
@media screen and (max-width: 600px) {
    .upper-content {
        max-width: 100%;
    }
    .upper-link-left {
        padding-left: 20px;
    }
    .upper-link-right {
        padding-right: 20px;
    }
}

.middle-content {
    max-width: 588px;
    margin: 0 auto 81px;
    background-color: white;
    font-size: 15px;
    text-align: center;
    color: #111111;
    display: table;
    /*height: calc(100% - 143px);*/
    height: calc(100vh - 143px);
    padding: 0 35px;
}
.middle-content a{
    color: #3db3c3;
}

.middle-child {
    display: table-row;
    vertical-align: middle;
}

.middle-image {
    margin: 30px auto 10px auto;
}

.footer {
    background-color: #fcee37;
    /*margin: 0 auto;*/
    margin: 0;
    text-align: right;
    bottom: 0;
    font-size: 13px;
    position: fixed;
    width: 100%;
    left: 0;
}

.footer-text {
    padding: 30px;
    color: #111111;
}

.subject {
    text-align: center;
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 1.3;

}
.subject p{
    margin: 0;
}

.description {
    font-size: 15px;
    margin: 0 auto 0 auto;
    /*max-width: 90%;*/
    padding: 0 23px;
}
.description p{
    margin: 0;
}

.button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.a-button-white {
    margin: 7px;
    border-radius: 50px;
    border: 1px solid #e0f8fc;
    min-width: 252px;

    color: #3db3c3;
    padding: 9px 15px;
    text-decoration: none;
}
.a-button-white:first-child{
    margin-left: 0;
}
.a-button-white:last-child{
    margin-right: 0;
}
.a-button-white:only-child{
    width: 100%;
}

.a-button-white:hover{
    background: #3db3c3;
    color: white;
}

.a-button {
    margin: 7px;
    border-radius: 50px;
    border: 1px solid #e0f8fc;
    min-width: 252px;

    background: #3db3c3;
    color: #ffffff !important;
    padding: 9px 15px;
    text-decoration: none;
}
.a-button:only-child{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.a-button:link, .a-button:visited, .a-button:active {
    color: #FFF;
}
.a-button:hover {
    color: #EFEFEF;
    background: #2da3b3;
}