body{
    height: 100vh;
    font-family: 'Maax-Regular','Poppins', sans-serif, arial;
    background-color: #ffffff;
}


.upperContent {
    width: 48%;
    margin: 0 auto;
    background-color: white;
    display: flex;
    justify-content: space-between;
    color: #111111;
}

.upperImage {
    display: block;
    margin: auto;
    height: 40px;
    width: 80px;
    padding-top: 20px;
}

.upperLinkRight {
    color: #111111;
    font-size: 16.5px;
    text-decoration: none;
    padding: 28px 0px 10px 0px;
    font-weight: 300;
}

.middleContent{
    display: flex;
    flex: 1;
    justify-content: center;
}

.middleChild {
    max-width: 410px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.body{
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
}

.middleImage {
    margin-bottom: 10px;
    /*margin: 30px auto 10px auto;*/
}

.footer {
    background-color: #fcee37;
    text-align: right;
    font-size: 13px;
    width: 100%;
}

.footerText {
    padding: 30px;
    color: #111111;
}

.subject {
    color: #3db3c3;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
}


@media only screen and (max-width: 600px) {
    .upperContent {
        box-sizing: border-box;
        width: 100%;
        padding: 0 20px;
    }
    .footerText{
        padding: 20px;
    }
}

@media only screen and (max-width: 400px){
    .subject{
        font-size: 26px;
    }
}

